import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import "./style.css"

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {

  return (
    <Layout {...props}>
      <Seo title='Submision' />
      <Divider />
      <Stack>
        <Main>
          
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Submission Guidelines'>
                <Text variant='p'>
                  The Newman Review is a professional e-publication of the
                  National Institute for Newman Studies, with articles related
                  to John Henry Newman’s legacy and his relevance for today. The
                  quality of articles is always more important than length, and
                  the ideal length varies from topic to topic. If you’re looking
                  for a concrete standard, the ideal submission length is 1000 –
                  4000 words +/- 200. Size does matter. The average person that
                  comes across your content via search engines doesn’t have time
                  to read tens of thousands of words and is looking for answers.
                  Here are some guidelines.
                </Text>
              </Section>
              {/* <Divider /> */}
              <br />
              <Section title='Importance of the first 40 words'>
                <Text variant='p'>
                  The first sentence of your post is arguably more important
                  than everything that will come after. The first sentence
                  should include key words about your topic without being overly
                  poetic or complex, but it also needs to be engaging. Take, for
                  example, the first sentence of this document: “The Newman
                  Review is a professional e-publication of the National
                  Institute for Newman Studies, with articles related to John
                  Henry Newman’s legacy and his relevance for today.” Readers
                  can decide whether to continue based on this sentence alone.
                </Text>
              </Section>
              <br />
              <Section title='1000–4000 words (+/- 200)'>
                <Text variant='p'>
                  Your submission should be at least 300 words for SEO purposes.
                  500-3000 words is commonly said to be the ideal length because
                  it is long enough to build a story around a topic but not too
                  long for readers to digest.
                </Text>
              </Section>
              <br />
              <Section title='Avoid redundancy'>
                <Text variant='p'>
                  No matter what length your submission ends up being, it should
                  not be repetitive. Only use the words and examples that are
                  necessary to get your point across, overdo it and your readers
                  will likely lose interest.
                </Text>
              </Section>
              <br />
              <Section title='Watch out for wordiness'>
                <Text variant='p'>
                  A great way to trim the length of your article is to delete
                  and rearrange wordy sentences. No one wants to read an
                  extemporaneous article. Decreasing wordiness will help you
                  achieve greater content clarity.
                </Text>
              </Section>

              <br />
              <Section title='Make your copy scannable'>
                <Text variant='p'>
                  Use subheads, bullet points, lists, and bolded copy to help
                  your readers easily get the main points without having to read
                  every word you write (as hurtful as that might be to you).
                  Don’t be egotistical enough to think people don’t scan. They
                  do. We do. You do. Make it easy for them to browse the article
                  without sacrificing good writing. Subheads and bolded copy
                  help move readers along effectively. Notice how subheads are
                  used in this article.
                </Text>
              </Section>

              <br />
              <Section title='Quality over quantity'>
                <Text variant='p'>
                  As we stated at the beginning of this article, quality is key.
                  If someone arrives at your article looking for information and
                  come across a poorly written post, it won’t matter how long or
                  short it is, they won’t be satisfied and will be unlikely to
                  return. When in doubt, act in the favor of putting forth
                  quality content over length.
                </Text>
              </Section>

              <br />
              <Section title='Spelling'>
                <Text variant='p'>
                  Please use American spelling, grammar rules, and punctuation.
                </Text>
              </Section>

              <br />
              <Section title='Capitalization'>
                <Text variant='p'>
                  • Always capitalize the first letter after a full stop (final
                  punctuation).
                </Text>
                <Text variant='p'>
                  • Capitalize the first personal pronoun “I.”.
                </Text>
                <Text variant='p'>
                  • Capitalize proper nouns.
                  <p>
                    - Cities, countries, nationalities, and languages
                    (Pittsburgh, France, American, English)
                  </p>
                  <p>
                    - Buildings, bridges, monuments, and tunnels (Brooklyn
                    Bridge, Apollo Theater, Buckingham Palace)
                  </p>
                  <p>- Days, months, official holidays (Friday, May, Easter)</p>
                  <p>- Planets (Earth, Jupiter)</p>
                </Text>
                <Text variant='p'>
                  • A good general rule is to use lowercase in reference to any
                  of the above, unless you’re discussing something specific.
                </Text>
                <Text variant='p'>
                  • Use lowercase for seasons and related phrases (spring,
                  fall), animals (pug, golden retriever, French bulldog),
                  scientific elements, food (unless it’s a brand name), places
                  of worship unless proper (church, mosque, temple, Roman
                  Catholic Church, Anglican Church)
                </Text>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}